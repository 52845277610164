import * as grid from "../styles/grid.module.scss";
import * as styles from '../styles/kontakt.module.scss';
import Caption from "../components/caption/Caption";
import ContentMargins from "../components/layout/ContentMargins";
import Layout from "../components/layout/layout";
import PageTitle from "../components/contentHeadings/PageTitle";
import React from 'react';
import Seo from "../components/Seo";
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import outputPixelDensities from "../data/defaultPixelDensities";

const KontaktPage = () => {
    return (
        <Layout>
            <Seo title={"Kontakt"} />
            <ContentMargins topMargin>
                <PageTitle>Kontaktinformasjon</PageTitle>
                <div className={grid.container}>
                    <div className={grid.xs12Sm6P12}>
                        <p className={styles.contactHeading}>Telefon</p>
                        <a href={'tel:48077349'}>480 77 349</a>
                        <p className={styles.contactHeading}>E-post</p>
                        <a href={'mailto:kontakt@benediktevippeextensions.no'}>kontakt@benediktevippeextensions.no</a>
                        <p className={styles.contactHeading}>Facebook</p>
                        <p>Du kan sende oss meldinger via vår <a href={"https://www.facebook.com/benediktevippeextensions/"}>facebook-side</a>. Dette er den raskeste måten å komme i kontakt med oss på.</p>
                        <p>Du kan se ledige tidspunkter og bestille time <Link to={"/booking"}>her</Link>. Timer kan avbestilles ved å bruke avbestillingslinken i e-posten du får når du bestiller en time.</p>
                        <p className={styles.contactHeading}>Postadresse</p>
                        <p className={styles.contactPostal}>
                            Benedikte Vippeextensions AS<br/>
                            Prinsens Gate 4B<br/>
                            0152 Oslo<br/>
                            Norge
                        </p>
                    </div>
                    <div className={grid.xs12Sm6P12}>
                        <div>
                            <figure>
                                <StaticImage
                                    src={"../images/salongen/innvendig-sentrum.jpg"}
                                    alt={"Innvendig Benedikte Vippeextensions Sentrum"}
                                    sizes={"(min-width: 960px): 434px, calc(100vw - 64px)"}
                                    width={434}
                                    outputPixelDensities={outputPixelDensities}
                                />
                                <figcaption>
                                    <Caption>
                                        Benedikte Vippeextensions Sentrum<br />
                                        Kirkegata 12A, 0153 Oslo
                                    </Caption>
                                </figcaption>
                            </figure>
                        </div>
                        <div>
                            <figure>
                                <StaticImage
                                    src={"../images/salongen/innvendig-grunerlokka.jpg"}
                                    alt={"Innvendig Benedikte Vippeextensions Grunerløkka"}
                                    sizes={"(min-width: 960px): 434px, calc(100vw - 64px)"}
                                    width={434}
                                    outputPixelDensities={outputPixelDensities}
                                />
                                <figcaption>
                                    <Caption>
                                        Benedikte Vippeextensions Grünerløkka<br />
                                        Søndre Gate 2, 0550 Oslo
                                    </Caption>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </ContentMargins>
        </Layout>
    );
};

export default KontaktPage;